import React from 'react';
import card from "../img/cardio.PNG";
import diplom from "../img/diplom.jpg";
import cert from "../img/cert.jpg";

const width = {
    width: "50%",
    padding: "1px"
}

const Kardio = () => {
    return (
        <div>
            <div style={{
                display: "flex"
            }}>
                <img style={width} src={cert} alt="Сертификат" />
                <img style={width} src={diplom} alt="Диплом" />
            </div>
            <img src={card} alt="Презентация"/>

        </div>
    );
};

export default Kardio;