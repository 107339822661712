import React from 'react';

const About = () => {
    return (
        <div>
            <p>ГМИП «НПП Волга» создана в августе 2020 года по инициативе семи МИП, расположенных по одному адресу на промплощадке индустриального парка «Рефлектор». Все предприятия образованы, начиная с 2014 года, на базе ООО «Волга-Свет», отделившегося в 2013 году от ОАО «НИИ «Волга» как его научная часть.</p>

            <p>Основные исследования предприятия ГМИП ведут в направлениях микро-и наноэлектроники на базе полупроводниковых наночастиц и коллоидных квантовых точек в рамках грантов Российского Фонда фундаментальных исследований (РФФИ) совместно с факультетом нанобиомедицинских технологий Саратовского госуниверситета.</p>

            <p>Основные разработки МИП ведут в рамках программ Фонда содействия развитию малых форм предприятий в научно-технической сфере (Фонд содействия).</p>

            <p>ГМИП имеет производственные площади и оборудование по замкнутому технологическому циклу микро- и оптоэлектроники.</p>

            <p>На предприятиях ГМИП работает коллектив опытных специалистов, в основном выпускников Саратовского госуниверситета.</p>

            <p>Специалисты ГМИП сотрудничают со многими научными организациями и отраслевыми предприятиями Саратова, Москвы, С-Петербурга.</p>

            <p>ГМИП «НПП Волга» управляется Советом директоров во главе с председателем и его двумя заместителями.</p>
        </div>
    );
};

export default About;