import React from 'react';

const Contacts = () => {
    return (
        <div>
            <p><b>Юридический адрес:</b> 410033, Саратов. Проспект им. 50 лет Октября, 101, офис 31.</p>

            <p><b>Жуков Николай Дмитриевич</b> – председатель Совета, директор ООО «Реф-Свет», кандидат физико-математических наук,
            почётный работник машиностроения РФ и Электронной промышленности СССР. E-mail: ndzhukov@rambler.ru Тел. 89603595735</p>

            <p><b>Абаньшин Николай Павлович</b> – заместитель председателя Совета, директор ООО «Волга-Свет». E-mail: npabanshin@mail.ru.
                Тел. 89172096773</p>

            <p><b>Мосияш Денис Сергеевич</b> – заместитель председателя Совета, генеральный директор ООО «НПП Волга». E-mail:
                denis-mos@yandex.ru. Тел. 89053812445</p>
        </div>
    );
};

export default Contacts;