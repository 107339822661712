import React from 'react';

const Fond = () => {
    return (
        <div>
            <p><b><u>Сотрудничество с Фондом содействия развитию малых форм предприятий в научно-технической сфере </u></b></p>
            <p>По состоянию за все годы деятельности предприятий ГМИП сотрудничество с Фондом вносит основной вклад в их развитие.
            Благодаря ему на предприятиях разработаны серия изделий медицинского приборостроения, разработано и изготовлено
            необходимое для опытного производства оборудование по замкнутому технологическому циклу, разработана и запущена
            программа создания региональной системы телемедицины, реализация которой позволит создать рынок для разработанных
            приборов.</p>

            <p><b>При содействии Фонда выполнены следующие проекты:</b></p>

            <p><b>Проект 6823</b>  “Миниатюрные источник и визуализатор рентгеновского излучения для медицины, промышленности,
                контроля безопасности: разработка, внедрение, организация производства и сбыта”</p>

            <p><b>Проект 21705</b> “Разработка и внедрение кардио-тестера для клинических и бытовых условий – микроминиатюрного
                электронного экспресс-измерителя комплексной характеристики состояния сердечнососудистой системы с
                использованием оригинального оптоэлектронного преобразователя”</p>

            <p><b>Проект 57534</b> “Разработка опытного образца программного-аппаратного комплекса для цифрового измерения содержания
                воды, протеинов, липидов и глюкозы в биосредах на основе спектрально-селективного оптоэлектронного излучателя”</p>
        </div>
    );
};

export default Fond;