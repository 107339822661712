import React from 'react';
import img1 from "../img/1.0bbba2e5.png";
import img2 from "../img/2.80be4edd.png";

const Ren = () => {
    return (
        <div style={{textAlign: "center"}}>
            <p>Предприятие ООО «Реф-Свет» разрабатывает миниатюрные рентгеновские</p>
            <p>излучатель и визуализатор для медицины при поддержке</p>
            <p>Фонда содействия развитию малых форм предприятий в научно-технической сфере (Фонд содействия инновациям)</p>

            <p>в рамках программы СТАРТ</p>

            <p>1 марта 2021 года подана заявка на конкурс «Коммерциализация-ХШ»</p>

            <p>«Модернизация производственного комплекса для коммерциализации разработанных по программе СТАРТ миниатюрных источника и визуализатора рентгеновского излучения для медицины»</p>
            <img src={img1} alt="logo" />
            <img src={img2} alt="logo2" />

        </div>
    );
};

export default Ren;