import React from 'react';

const Header = () => {
    return (
        <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">НПП «Волга»- группа малых инновационных предприятий (МИП)</h1>

            <h3>ГМИП «НПП Волга»</h3>

            <h3>Управляющая компания – ООО «НПП Волга» (ИНН 6453157138)</h3>

            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Участники – ООО «Волга-Свет» (6453103580), «Реф-Свет» (6453134564), «Реф-Мед» (6453142050), «Волга-Старт»
                (6453160130), «Фотоника РВ» (6453147605), «Реф-Волга-Свет» (6453142075).</p>

            <h2><b>Направления деятельности компании:</b></h2>

            <ul className="text-base">
                <li>Научные исследования и разработки в области естественных и технических наук. Проведение НИОКР под заказ.</li>

                <li>Исследования, разработка технологий и изготовление полупроводниковых наночастиц и коллоидных квантовых точек.</li>

                <li>Разработки и производство изделий медицинского приборостроения, в том числе для систем телемедицины.</li>

                <li>Разработка и производство элементов для фотовольтаических солнечных систем.</li>
            </ul>
        </div>
    );
};

export default Header;