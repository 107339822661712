import React from 'react';

const Nau = () => {
    return (
        <div>
            <p>За пять лет деятельности предприятий ГМИП их специалистами получено около 20 патентов, опубликовано более 50
            научных работ в рецензируемых журналах и сборниках. Зарегистрировано в системе ЦИТИС более 15 научно-технических
            отчётов. Разработаны несколько типов изделий биомедицинского приборостроения: рентгеновские миниатюрные излучатель
            и визуализатор; кардиотестер; неинвазивный измеритель липидного состава биосред; измерители воды, белка и жиров
            в мясо-молочной продукции.</p>
            <p>Разработана программа создания региональной системы телемедицины на примере массовой диспансеризации сельского
            населения в расчёте на развитие в стране систем здоровьесбережения. Указанные разработанные приборы найдут
                применение в этих системах.</p>
        </div>
    );
};

export default Nau;